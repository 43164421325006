export default {
    state: {
        timestamp: "00:00:00.000",
        removeDrawing: false,
        saveDrawing: false,
        timestampHighlight: false,
        drawing: undefined,
        closeNewHighlight: false,
        editHighlight: undefined,
        keyframe: undefined,
        highlight: undefined,
    },
    mutations: {
        setKeyframe(state, keyframe) {
            state.keyframe = keyframe;
        },
        cancelEditHighlight(state) {
            state.editHighlight = undefined;
        },
        setHighlightShow(state, highlight) {
            state.highlight = highlight;
        },
        setEditHighlight(state, highlight) {
            state.editHighlight = highlight;
        },
        setTimestampHighlight(state, timestamp) {
            state.timestampHighlight = timestamp
        },
        setTimeStamp(state, timestamp) {
            state.timestamp = timestamp;
        },
        closeHighlight(state) {
            state.closeNewHighlight = !state.closeNewHighlight;
        },
        cancelDrawing(state) {
            state.removeDrawing = !state.removeDrawing;
        },
        saveDrawing(state) {
            state.saveDrawing = !state.saveDrawing;
        },
        setDrawing(state, drawing) {
            state.drawing = drawing;
        }
    },
    actions: {},
    getters: {}
}
