import store from '@/store';

function error(message)
{
    store.dispatch('messages/setError', message);
}

function warn(message)
{
    store.dispatch('messages/setWarning', message);
}

function success(message)
{
    store.dispatch('messages/setSuccess', message);
}

function info(message)
{
    store.dispatch('messages/setInfo', message);
}

function dismiss(id) 
{
    store.commit('messages/dismissMessage', { id: id });
}

function all()
{
    return store.getters['messages/getMessages'];
}

export default {error, warn, success, info, dismiss, all};