import axios from 'axios';
import store from '@/store';
import Cookies from 'js-cookie';

const login_path = "v1/coach/auth/login";
const logout_path = "v1/coach/auth/logout";

function isLoggedIn()
{
    return Cookies.get('access_token') != null;
}

function isVerified()
{
    const user = store.getters['getUser'];

    return user != null && user.verified;
}

function login(formData, remember = false)
{
    return new Promise((resolve, reject) =>{
        try {

            validate_login(formData);

            const data = {
                'email':    formData.email.value,
                'password': formData.password.value,
            }

            axios.post(login_path, data)
                .then((response)=>{
                    console.log('ingelogd:', response.data.coach);

                    const attributes = {
                        expires: remember === true ? 30 : 1,  // time to live in days
                        // domain: process.env.VUE_APP_URL, 
                        sameSite: 'strict'
                    };
                    Cookies.set('access_token', response.data.token.access_token, attributes)
                    Cookies.set('refresh_token', response.data.token.refresh_token, attributes)
                    Cookies.set('expires_in', response.data.token.expires_in, attributes)

                    store.commit('setUser', response.data.coach);
                    store.commit('setToken', response.data.token.access_token);

                    resolve(response);
                })
                .catch((err) => {
                    reject(err.response.data);
                });

        } catch (err) {
            reject(err);
        }
    });
}

function validate_login(data)
{   
    var errors = {};

    if(data.email.value == ""){
        errors.email = [
            'Email niet ingevuld'
        ];
    }
    if(data.password.value == ""){
        errors.password = [
            'Wachtwoord niet ingevuld'
        ];
    }

    if (! isEmpty(errors)){
        throw {
            'message': 'local validation failed',
            'errors': errors
        };
    }
}

function isEmpty(obj) 
{
    return Object.keys(obj).length === 0;
}

function logout()
{
    return new Promise((resolve, reject) =>{
        axios.post(logout_path, null)
            .then(() => {
                removeTokens();
                resolve();
            })
            .catch((err) => {
                if(err.response.status === 401){
                    // User is already unauthenticated, delete local tokens.
                    removeTokens();
                    resolve();
                }

                reject(err.response.data);
            });
    });
}

function removeTokens()
{
    store.commit('setToken', null);
    store.commit('setUser', null);

    const attributes = {
        // domain: process.env.VUE_APP_URL, 
        sameSite: 'strict'
    };
    Cookies.remove('access_token', attributes);
    Cookies.remove('refresh_token', attributes);
    Cookies.remove('expires_in', attributes);
}

export {login, logout, isLoggedIn, isVerified, removeTokens};