import Cookies from 'js-cookie';
import { setI18nLanguage } from "@/i18n.js";

function setLocalStorage (key, item)
{
    if (item !== undefined && item !== null){
        localStorage.setItem(key, item);
    } else {
        localStorage.removeItem(key);
    }
}

export default {
    state: {
        token: null,
        user: null
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setUser(state, user) {
            state.user = user;

            setLocalStorage("user", user ? JSON.stringify(user) : null);

            const locale = user?.locale;
            if(locale)
                setI18nLanguage(locale);
        }
    },
    actions: {},
    getters: {
        getToken: (state) => {
            if (state.token === null) {
                return Cookies.get('access_token');
            }
            return state.token;
        },
        getUser: (state) => {
            if (state.user === null) {
                state.user = JSON.parse(localStorage.getItem("user"));
            }
            return state.user;
        }
    }
}
