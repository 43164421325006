import { isset } from "@/helper/javascript/hurrrrrdurrrrr";

export default {
    namespaced: true,

    state: {
        form: {
            // birth_day: null,
        
            // Location
            street: "",
            city: "",
            province: "",
            zip: "",
            country: "",

            // Banking
            iban: "",
            bic: "",

            // profile
            profile_image: null,    //image?
            about: "",
            hour_price: "",       // number

            // Profession
            active: false,           //boolean
            field_position: "",   // in [GK, CB, BW, CM, MD, CAM, WI, ST]
            expertise: "",

            // Answers
            answers: {},
        },
        // place to set errors
        message: "",
        errors: null,
    },
    mutations: {
        setWalkthroughValue({form}, payload) {
            if(payload.key !== 'err' && payload.key !== 'answers'){
                form[payload.key] = payload.value;
            }
        },

        setAnswer({form}, payload) {
            if (isset(payload.question_id) && isset(payload.answer_id)){
                form.answers[payload.question_id] = {
                    id: payload.question_id,
                    answer: {
                        id: payload.answer_id
                    }
                };
            }
            
        },
        setErrors(store, payload) {
            if(isset(payload.message)){
                store.message = payload.message;
            }
            if(isset(payload.errors)){
                store.errors = payload.errors;
            }
        },
        setError(store, payload) {
            if(isset(store.errors) && isset(store.errors[payload.field])){
                store.errors[payload.field] = payload.error
            }
        }
    },
    getters: {
        answerByQuestionId: ({form}) => (question_id) => {
            const ans_obj = form.answers[question_id];
            if (!ans_obj){
                return null;
            }
            return ans_obj.answer.id;
        },
        form: ({form}) => {
            return form;
        },
        error: (store) => (field) => {
            if (!store.errors){
                return [];
            }
            return store.errors[field];
        },
    }
}
