import { createStore } from 'vuex'
import authStore from './authStore';
import walkthroughStore from './walkthroughStore';
import rapportStore from './rapportStore';
import messageStore from './messageStore';
import loadingStore from './loadingStore';
import highlightStore from "@/store/highlightStore";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authStore,
    walkthrough: walkthroughStore,
    rapport: rapportStore,
    messages: messageStore,
    highlight: highlightStore,
    loading: loadingStore,
  }
})
