import { createApp } from 'vue'
import App from './App.vue'
import i18n, { requestedLanguage, setI18nLanguage } from "./i18n";
import router from './router'
import store from './store'

import '@/assets/css/tailwind.css'
import './assets/scss/main.scss'

// configure axios in this file
import "./axios";

/*
 * Not yet working in vue next
 */


const app = createApp(App).use(store).use(router).use(i18n)

setI18nLanguage(requestedLanguage());

app.mount('#app')
