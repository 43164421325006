<template>
    <div id="app">
        <Messages />
        <LoadingSpinner />
        <router-view/>
    </div>
</template>

<script>
import Messages from "@/components/Messages";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
    components: {
        Messages,
        LoadingSpinner
    },
}
</script>

<style>
</style>
