<template>
    <div class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end z-50">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
            <div class="rounded-lg shadow-xs overflow-hidden">
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            <svg v-html="icon" :class="'h-6 w-6 text-'+color+'-400'" fill="currentColor" viewBox="0 0 24 24"/>
                        </div>
                        <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p class="text-sm leading-5 font-medium text-gray-900">
                                {{ title }}
                            </p>
                            <p class="mt-1 text-sm leading-5 text-gray-800">
                                 {{ message.text }}
                            </p>
                        </div>
                        <div class="ml-4 flex-shrink-0 flex">
                            <button :class="button_class" @click="dismiss">
                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    name: "Message",
    props: ['message'],
    methods:{
        dismiss (){
            this.$emit('dismiss', this.message.id);
        }
    },
    created(){
        setTimeout(() => this.dismiss(), 6500)
    },
    computed:{
        button_class: function (){
            return '"inline-flex text-gray-400 hover:text-gray-500 transition focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Dismiss';
        },
        color: function(){
            switch(this.message.type){
                case 'error':
                    return 'red';

                case 'warning':
                    return 'yellow';

                case 'succes':
                    return 'green';

                case 'info':
                    return 'blue';

                default:
                    return 'red';
            }
        },
        title: function(){
            switch(this.message.type){
                case 'error':
                    return $t("alerts.error");

                case 'warning':
                    return $t("alerts.warning");

                case 'succes':
                    return $t("alerts.success");

                case 'info':
                    return $t("alerts.info");

                default:
                    return '';
            }
        },
        icon: function(){
            switch(this.message.type){
                case 'error': // Heroicon name: x-circle
                    return '<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />';

                case 'warning': // Heroicon name: exclamation
                    return '<path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />';

                case 'succes': // Heroicon name: check-circle
                    return '<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />';

                case 'info': // Heroicon name: information-circle
                    return '<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />';

                default: // Heroicon name: x-circle
                    return '<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />';
            }
        },
    }
}
</script>