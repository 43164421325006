const Login                     = () => import("@/views/Login");
const Dashboard                 = () => import("@/views/Dashboard");
const NotFound                  = () => import("@/views/NotFound");
const Register                  = () => import("@/views/Register");
const RegisterSteps             = () => import("@/views/Register/Steps");
const ProfessionStep            = () => import("@/views/Register/ProfessionStep");
const LocationStep              = () => import("@/views/Register/LocationStep");
const SportQuestionsSteps       = () => import("@/views/Register/SportQuestionsSteps");
const ProfileStep               = () => import("@/views/Register/ProfileStep");
const EmailVerificationVerify   = () => import("@/views/Register/emailVerification/Verify");
const EmailVerificationResend   = () => import("@/views/Register/emailVerification/Resend");
const WrapupStep                = () => import("@/views/Register/WrapupStep");
const Review                    = () => import("@/views/Review");
const ExplanationStep           = () => import("@/views/Register/ExplanationStep");
const ForgotPassword            = () => import("@/views/Password/Forgot");
const ResetPassword             = () => import("@/views/Password/Reset");
const ReviewHistory             = () => import("@/views/ReviewHistory");
const Profile                   = () => import("@/views/Profile");
const Account                   = () => import("@/views/Account");

import guest from "./middleware/guest";
import auth from "./middleware/auth";
import log from "./middleware/log";
import verified from "./middleware/verified";
import notVerified from "./middleware/notVerified";
import walkthrough from "./middleware/walkthrough";
import noWalkthrough from "./middleware/noWalkthrough";
import trainerWithClub from "./middleware/TrainerWithClub";
import trainerWithoutClub from "./middleware/TrainerWithoutClub";


const first_registration_step = 'register.steps.first';

export { first_registration_step };

// define middleware for most routes (where the user has completed the walkthrough/validation)
const default_middleware = [log, auth, walkthrough, verified, trainerWithClub];

const routes = [
    {
        path: '/',
        redirect: { name: 'login' }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: default_middleware,
        },
    },
    {
        path: '/reviews',
        name: 'review.history',
        component: ReviewHistory,
        meta: {
            middleware: default_middleware,
        },
    },
    {
        path: '/review/:id',
        name: 'review',
        component: Review,
        meta: {
            middleware: default_middleware,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            middleware: default_middleware,
        },
    },
    {
        path: '/account',
        name: 'account',
        component: Account,
        meta: {
            middleware: default_middleware,
        },
    },

    {
        path: '/club/select',
        name: 'club.select',
        component: () => import("@/views/Club/Select.vue"),
        meta: {
            middleware: [log, auth, walkthrough, verified, trainerWithoutClub],
        },
    },

    {
        path: '/games',
        name: 'game.index',
        component: () => import("@/views/Game/Index.vue"),
        meta: {
            middleware: default_middleware,
        },
    },
    {
        path: '/game/:id',
        name: 'game.show',
        component: () => import("@/views/Game/Show.vue"),
        meta: {
            middleware: default_middleware,
        },
        props: true
    },
    {
        path: '/teams',
        name: 'team.index',
        component: () => import("@/views/Team/Index.vue"),
        meta: {
            middleware: default_middleware,
        },
    },
    {
        path: '/team/:id',
        name: 'team.show',
        component: () => import("@/views/Team/Show.vue"),
        meta: {
            middleware: default_middleware,
        },
        props: true
    },

    {
        path: '/payment/success',
        name: 'payment.status.success',
        component: () => import("@/views/Payment/Success"),
        meta: {
            middleware: default_middleware,
        },
        props: true,
    },
    {
        path: '/payment/cancel',
        name: 'payment.status.cancel',
        component: () => import("@/views/Payment/Cancel"),
        meta: {
            middleware: default_middleware,
        },
        props: true,
    },
    
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            middleware: [log, guest],
        },
    },
    {
        path: '/api/v1/coach/auth/password/reset/:token',
        redirect: ({ params }) => {return { name: 'password.reset', params }}
    },
    {
        path: '/password/reset/:token',
        name: 'password.reset',
        component: ResetPassword,
        meta: {
            middleware: [log, guest],
        },
    },
    {
        path: '/password/reset',
        name: 'password.forgot',
        component: ForgotPassword,
        meta: {
            middleware: [log, guest],
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            middleware: [log, guest],
        },
    },
    {
        path: '/email/verify/',
        name: 'email-verification.resend',
        component: EmailVerificationResend,
        meta: {
            middleware: [log, auth, notVerified],
        },
    },
    {
        path: '/api/v1/coach/auth/email/verify/:id/:hash',
        redirect: ({ params, query }) => {return { name: 'email-verification.verify', params, query }}
    },
    {
        path: '/email/verify/:id/:hash',
        name: 'email-verification.verify',
        component: EmailVerificationVerify,
        meta: {
            middleware: [log, auth, notVerified],
        },
    },
    {
        path: '/register/step',
        name: 'register.steps',
        component: RegisterSteps,
        meta: {
            middleware: [log, auth, noWalkthrough],
        },
        children: [
            {
                path: 'first',
                name: 'register.steps.first',
                component: ExplanationStep,
                meta: {
                    number: 0,
                    title: 'register.explanation.title',
                },
            },
            {
                path: 'profile',
                name: 'register.steps.profile',
                component: ProfileStep,
                meta: {
                    number: 1,
                    title: 'register.profile.title',
                }
            },
            {
                path: 'prof',
                name: 'register.steps.prof',
                component: ProfessionStep,
                meta: { 
                    number: 2,
                    title: 'register.profession.title',
                }
            },
            {
                path: 'adress',
                name: 'register.steps.adress',
                component: LocationStep,
                meta: { 
                    number: 3,
                    title: 'register.location.title',
                }
            }
        ]
    },
    {
        path: '/register/step/questions/:id',
        name: 'register.steps.questions',
        component: SportQuestionsSteps,
        meta: {
            middleware: [log, auth, noWalkthrough],
            number: 5,
        },
    },
    {
        path: '/register/step/wrapup',
        name: 'register.steps.wrapup',
        component: WrapupStep,
        meta: {
            middleware: [log, auth, noWalkthrough],
        },
    },
    {
        path: "/:catchAll(.*)",
        name: '404',
        component: NotFound,
    }
]

export default routes

