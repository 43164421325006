import store from '@/store';


export default (to, from, next) => {
    const user = store.getters['getUser'];

    if (user?.is_trainer && user?.club) {
        next({ name: 'dashboard' });
        return false;
    }
}
