import store from '@/store';

function setLoading(loading) 
{
    store.commit('loading/setLoading', { loading });
}

function isLoading()
{
    return store.getters['loading/getLoading'];
}

export {isLoading, setLoading};