import { createI18n } from "vue-i18n";
import i18n from "@/i18n";
import store from '@/store';
import axios from "axios";

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  localStorage.setItem('lang', lang);
  axios.defaults.headers.common["Accept-Language"] = lang;

  const htmlRoot = document.querySelector("html");
  if (htmlRoot) {
    htmlRoot.setAttribute("lang", lang);
  }

  return lang;
}

export function requestedLanguage() {
  const user = store.getters['getUser'];
  const navLocale = navigator.browserLanguage || navigator.language || navigator.userLanguage;
  const navLanguage = navLocale && navLocale.startsWith('nl') ? 'nl' : 'en';
  return user?.locale || localStorage.lang || navLanguage;
}

export default createI18n({
  locale: requestedLanguage(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
