export default {
    namespaced: true,
    
    state: {
        isLoading: false,
    },
    mutations: {
        setLoading(state, payload) {
            state.isLoading = payload.loading;
        },
    },
    actions: {},
    getters: {
        getLoading: (state) => {
            return state.isLoading;
        },

    }
}
