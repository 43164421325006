
function checkSubjectDone(subject)
{
    return subject.questions.every(isQuestionDone);
}

function isQuestionDone(question)
{
    switch (question.type) {
        case 'checkbox':
            // every checkbox type question should have a conclusion
            return typeof question.conclusion === 'string' && question.conclusion.length !== 0;

        case 'score':
            return checkScoreQuestionDone(question)

        default:
            throw new Error("Could not check if question is done");
    }
}

function checkScoreQuestionDone(scoreQuestion)
{
    const isOptionDone = option => option.value !== null

    return  scoreQuestion.options.every(isOptionDone);
}

// this function checks every answer and marks subjects as done if appropriate.
// Highly inefficient, sorry ¯\_(ツ)_/¯
function updateFinishedStatus(state) {
    let reviewDone = true;
    state.subjects.forEach((subject) => {
        subject.subject_done = checkSubjectDone(subject)
        reviewDone = reviewDone && subject.subject_done;
    });
    state.reviewDone = reviewDone;
}

export function isGeneralSubject(subj) {
    return subj.title === "Algemeen" || subj.title === "General"
}

export default {
    state: {
        subjects: [],
        videoShow: false,
        video: undefined,
        reviewDone: true,
        showOnlyVideo: false
    },
    mutations: {
        setVideo(state, video) {
            state.video = video;
        },
        setVideoShow(state, show = false) {
            state.videoShow = !state.videoShow;
            state.showOnlyVideo = show;
        },
        setSubjects(state, subjects) {
            state.subjects = subjects;

            if (state.subjects === null) {
                return ;
            }

            updateFinishedStatus(state)
        },
        updateSubjectQuestion(state, payload) {
            const { value, name, type } = payload;

            state.subjects.forEach((subject) => {
                subject.questions.forEach((question) => {
                    question.options.forEach((option) => {
                        if (option.title === name) {
                            if (type === 'checkbox') {
                                option.checked = value
                            } else {
                                option.value = parseInt(value)
                            }
                        }
                    });
                });
            });

            updateFinishedStatus(state)
        },
        updateSubjectConclusion(state, payload) {
            let value = payload.value;
            let targetDescription = payload.subject ? payload.subject.description : '';
            state.subjects.forEach((subject) => {
                subject.questions.forEach((question) => {
                    if (question.type === "checkbox") {
                        if (question.description === targetDescription) {
                            question.conclusion = value;
                        }
                    }
                });
            });
            this.commit("setSubjects", state.subjects);
        }
    },
    actions: {},
    getters: {
        getGeneralSubjectAnswers: (state) => {
            if (!state.subjects) {
                return [];
            }
            const generalSubject = state.subjects.find(isGeneralSubject);
            if(!generalSubject) {
                return [];
            }
            return generalSubject.questions
        }
    }
}
