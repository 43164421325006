import { isVerified } from "@/helper/api/AuthenticationHelper";
import messages from "@/helper/store/messageHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default (to, from, next) => {
  if (!isVerified()) {
    next({ name: 'email-verification.resend' });
    messages.warn($t('general.unverified'));
    return false;
  }
}
