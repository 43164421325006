import { hasCompletedWalkthrough } from "@/helper/api/RegistrationHelper";
import { first_registration_step } from "@/router/router-config";
import messages from "@/helper/store/messageHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default (to, from, next) => {
    if (!hasCompletedWalkthrough()) {
        next({ name: first_registration_step });
        if(from.name !== 'login'){
            messages.warn($t('register.questions.required'));
        }
        return false;
    }
}
