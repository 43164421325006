import store from "@/store";
import axios from "axios";
import Cookies from 'js-cookie';
import messages from "@/helper/store/messageHelper";
import { removeTokens } from "@/helper/api/AuthenticationHelper";
import router from './router'
import i18n from "@/i18n";
const $t = i18n.global.t;

// load token from cookies if it already exists.
const token = Cookies.get('access_token');
if (token){
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// subscribe to vuex changes, specifically; setToken.
store.subscribe(mutation => {
  if (mutation.type === "setToken"){
    var token = mutation.payload ? `Bearer ${mutation.payload}` : null;
    axios.defaults.headers.common["Authorization"] = token;
  }
});

axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';
axios.defaults.headers.common["Access-Control-Allow-Methods"] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
axios.defaults.headers.common["Access-Control-Allow-Headers"] = 'Origin, Content-Type, X-Auth-Token';
axios.defaults.headers.common["Accept"] = 'application/json';

// Set the base url of axios.
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// intercept 401 responses and "empty" responses (aka server could not be reached)
// https://stackoverflow.com/questions/52096412/vue-js-and-axios-redirect-on-401
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // console.log(error);
        if (!error.response) {
          messages.error($t('general.connection_error'));
        } else if (error.response.status === 401) {
          removeTokens();
          router.replace({
              name: "login",
          });
          messages.error($t('general.session_expired'));
          
          // return;
        } else if (error.response.status >= 500) {
          // global catch for 5xx errors
          messages.error($t('general.server_error'));
          console.error(error.response.message)
          return;
        }

        return Promise.reject(error);
    }
);


