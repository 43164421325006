import axios from 'axios';
import store from '@/store';
import Cookies from 'js-cookie';

const register_path = "v1/coach/auth/register";
const walkthrough_questions_path = "v1/coach/walkthrough";   // get
const walkthrough_walkthrough_path = "v1/coach/walkthrough"; // patch
const resend_email_verification_path = "v1/coach/auth/email/resend";
const verify_email_path = "v1/coach/auth/email/verify/"; // + user_id + hash + ?expires= + &signature



function hasCompletedWalkthrough()
{
    const user = store.getters['getUser'];

    return user?.walkthrough || user?.is_trainer;
}

function register(formData)
{
    return new Promise((resolve, reject) =>{
        try {
            const data = {
                'first_name':           formData.first_name.value,
                'last_name':            formData.last_name.value,
                'email':                formData.email.value,
                'email_confirmation':   formData.email_confirmation.value,
                'password':             formData.password.value,
                'password_confirmation':formData.password_confirmation.value,
                'birth_day':            formData.birth_day.value,
                'sport_id':             formData.sport_id.value,
                'locale':               formData.locale.value,
                'terms_and_conditions': formData.terms_and_conditions.value ? '1' : '0',
                'accept_newsletter':    formData.accept_newsletter.value ? '1' : '0',
                'is_trainer':           formData.isTrainer.value ? '1' : '0',
            }

            axios.post(register_path, data)
                .then((response)=>{
                    const attributes = {
                        // expires: remember === true ? 30 : 1,  // time to live in days
                        // domain: process.env.VUE_APP_URL, 
                        sameSite: 'strict'
                    };
                    Cookies.set('access_token', response.data.token.access_token, attributes)
                    Cookies.set('refresh_token', response.data.token.refresh_token, attributes)
                    Cookies.set('expires_in', response.data.token.expires_in, attributes)

                    store.commit('setUser', response.data.coach);
                    store.commit('setToken', response.data.token.access_token);


                    resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err.response.data);
                });

        } catch (err) {
            reject(err);
        }
    });
}

function sendWalkthrough()
{
    let data = Object.assign({}, store.getters['walkthrough/form']);

    // convert answers object to json.
    data.answers = JSON.stringify(data.answers);

    // convert all values to a formData object
    let formData = new FormData();
    // console.log('---------------------------------------------------');
    for(const prop in data){
        // console.log(prop + ": " + data[prop]);
        formData.append(prop, data[prop]);
    }
    // console.log('---------------------------------------------------');

    return new Promise((resolve, reject) =>{
        axios.post(walkthrough_walkthrough_path + '?_method=patch', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((response)=>{
                store.commit('setUser', response.data.coach);
                resolve(response.data);
            })
            .catch((err) => {
                // if(err.response.status === 403){
                //     // User has completed walkthrough already
                //     resolve();
                // }

                reject(err.response.data);
            });
    });
}

function getWalkthroughQuestions()
{
    return new Promise((resolve, reject) =>{
        axios.get(walkthrough_questions_path)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function resendVerificationEmail()
{
    return new Promise((resolve, reject) =>{
        axios.post(resend_email_verification_path)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function verifyEmail(user_id, hash, expires, signature)
{
    return new Promise((resolve, reject) =>{
        const url = verify_email_path  + user_id + '/' + hash + '?expires=' + expires + '&signature=' + signature;
        axios.get(url)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function register_validation(data)
{
    // console.log('data to be validated:', data);

    const url = walkthrough_walkthrough_path + '?_method=patch';
    // convert all values to a formData object
    const formData = new FormData();
    for(const prop in data){
        // console.log(prop + ": " + data[prop], typeof data[prop]);

        formData.append(prop, data[prop]);
    }
    const config = {headers: {'Content-Type': 'multipart/form-data'}};

    return new Promise((resolve, reject) =>{
        axios.post(url, formData, config)
            .then(()=>{
                resolve({ success: true });
            })
            .catch((err) => {
                // check for 422 validation errors only.
                if(err.response.status === 422){
                    // find the errors matching the local keys to be tested, the rest can be discarded
                    const local_keys = Object.keys(data);
                    const errors = {}
                    for (const [key, value] of Object.entries(err.response.data.errors)) {
                        if (local_keys.includes(key)){
                            errors[key] = value;
                        }
                    }
                    console.log('errors', errors)
                    if(isEmptyObject(errors)){
                        resolve({ success: true });
                    } else {
                        resolve({ success: false, message: err.response.data.message, errors: errors });
                    }
                } else {
                    reject(err.response.data);
                }
            });
    });
}

function isEmptyObject(obj)
{
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

export {register, sendWalkthrough, getWalkthroughQuestions, hasCompletedWalkthrough, resendVerificationEmail, verifyEmail, register_validation};