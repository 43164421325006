<template>
    <div>
        <Message v-for="message in messages" :key="message.id" :message="message" v-on:dismiss="dismissMessage" />
    </div>
</template>

<script>
import Message from "@/components/messages/Message";
import messages from "@/helper/store/messageHelper";

export default {
    name: "Messages",
    methods:{
        dismissMessage(id){
            messages.dismiss(id);
        },
    },
    computed:{
        messages: function(){
            return messages.all();
        } 
    },
    components: {
        Message,
    },
}
</script>